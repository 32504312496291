.sequenceEdit {
    max-width: 700px;
    margin: auto;

    .publish-toggle{
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);   
        border-radius: 500rem;
    }

    .ui.segment {

        span.inline {
            display: block;

            &.title {
                font-weight: 500;
                font-size: 16px;
                line-height: 19.36px;
                color: #000000;
            }

            &.status {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;

                &.draft {
                    color: #FFCC49;
                }

                &.published {
                    color: #1fd5b9;
                }
            }

            &.statusDescription {
                font-weight: 500;
                font-size: 12px;
                line-height: 14.52px;
                color: #C4C4C4;
            }
        }

        .ui.fitted.toggle.checkbox {
            float: right;
            top: 10px;

            input:checked~.box:before, input:checked~label:before {
                background-color: #1fd5b9!important;
            }
        }
    }

    .pictureBox, .videoBox {
        margin-bottom: 30px;

        label {
            display: block;
            margin: 0 0 0.28571429rem 0;
            color: rgba(0,0,0,.87);
            font-weight: 700;
            text-transform: none;
            font-family: PoppinsBold;
            font-size: .92857143em;
        }
    }

    .subsections {
        position: relative;
        margin-top: 30px;

        .subsection {
            cursor: pointer;
            border-bottom: 1px solid #F4F4F4;
            border-top: 1px solid #F4F4F4;
            height: 55px;

            span.title {
                font-weight: 500;
                font-size: 1em;
                line-height: 3.5em;

                &.disable {
                    color: #C4C4C4;
                }

                &.red {
                    color: red;
                }
            }

            div.counter {
                position: absolute;
                right: 8px;
                display: inline;

                span, i {
                    color: #C4C4C4;
                    font-weight: 500;
                    font-size: 1em;
                    line-height: 3.5em;
                }
            }
        }
    }
}